import { IconProps } from '@/utilities/types/Icon';

const CircleQuestion = (props: IconProps) => {
  const { height = 16, width = 16 } = props;
  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 22 22"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2332 7.41259C10.8131 7.34054 10.3811 7.41948 10.0136 7.63543C9.64621 7.85137 9.36705 8.1904 9.22562 8.59245C9.06024 9.06256 8.54507 9.30961 8.07495 9.14423C7.60483 8.97885 7.35779 8.46368 7.52317 7.99356C7.80603 7.18946 8.36435 6.51142 9.09923 6.07952C9.83411 5.64763 10.6981 5.48975 11.5383 5.63385C12.3784 5.77796 13.1404 6.21475 13.6894 6.86685C14.2382 7.51883 14.5386 8.34397 14.5375 9.19618C14.5371 10.5771 13.5133 11.4891 12.7821 11.9765C12.4388 12.2054 12.1004 12.3813 11.8304 12.5066V12.8663C11.8304 13.3646 11.4264 13.7686 10.9281 13.7686C10.4297 13.7686 10.0257 13.3646 10.0257 12.8663V11.9024C10.0257 11.5144 10.2738 11.1698 10.6418 11.0467L10.6562 11.0416C10.67 11.0367 10.6928 11.0285 10.7231 11.0169C10.784 10.9937 10.8745 10.9576 10.9846 10.9087C11.2073 10.8097 11.4974 10.664 11.7811 10.4749C12.4033 10.06 12.7328 9.61869 12.7328 9.19536V9.19402C12.7334 8.76782 12.5832 8.35515 12.3087 8.02909C12.0342 7.70304 11.6532 7.48464 11.2332 7.41259Z"
        fill="currentColor"
      />
      <path
        d="M11.0001 14.6094C10.5018 14.6094 10.0978 15.0134 10.0978 15.5118C10.0978 16.0101 10.5018 16.4141 11.0001 16.4141H11.0092C11.5075 16.4141 11.9115 16.0101 11.9115 15.5118C11.9115 15.0134 11.5075 14.6094 11.0092 14.6094H11.0001Z"
        fill="currentColor"
      />
      <path
        d="M1.07422 11C1.07422 5.51806 5.51821 1.07407 11.0001 1.07407C16.4821 1.07407 20.9261 5.51806 20.9261 11C20.9261 16.4819 16.4821 20.9259 11.0001 20.9259C5.51821 20.9259 1.07422 16.4819 1.07422 11ZM11.0001 2.87879C6.51492 2.87879 2.87893 6.51478 2.87893 11C2.87893 15.4852 6.51492 19.1212 11.0001 19.1212C15.4854 19.1212 19.1214 15.4852 19.1214 11C19.1214 6.51478 15.4854 2.87879 11.0001 2.87879Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CircleQuestion;
